import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Jumbotron} from "reactstrap";

class ErrorPage extends Component {

  render(){
    return (
      <Jumbotron>
        <h1 className="display-3">Page Not Found</h1>
      </Jumbotron>
      );
  }
}

export default ErrorPage;
