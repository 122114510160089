import React, {Component} from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import {DropdownToggle, DropdownItem, DropdownMenu, UncontrolledDropdown} from "reactstrap";
import {AuthorisationHelper} from "./helpers";

export class SoftwareDropdown extends Component {
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      dropdownOpen: false,
      softwareList: props.softwareList,
      itemSelected: props.software,
    };
    this.onSelect = this.onSelect.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  onSelect(e){
    let software = e.currentTarget.textContent;
    this.setState({itemSelected: software});
    this.props.onItemSelected(software);
  }
  render(){
    if(AuthorisationHelper.userIsAdminAuthorised(this.props.userRoles)) {
      return (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            {this.state.itemSelected}
          </DropdownToggle>
          <DropdownMenu>
            {this.state.softwareList.map(software => {
              return (<DropdownItem key={software} onClick={this.onSelect}>
                  {software}
                </DropdownItem>)
            })}
          </DropdownMenu>
        </UncontrolledDropdown>)
    } else {
      return null
    }
  }
}

SoftwareDropdown.propTypes = {
  softwareList: PropTypes.array.isRequired,
  software: PropTypes.string.isRequired,
  userRoles: PropTypes.array.isRequired,
  onItemSelected: PropTypes.func.isRequired,
};
