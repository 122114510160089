import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Amplify, {Auth} from 'aws-amplify';
import EpixNavbar from "./EpixNavbar";
import FileBrowser from "./FileBrowser";
import {
  VerifyContact, ForgotPassword, RequireNewPassword, withAuthenticator, AmplifyTheme
} from 'aws-amplify-react';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import {Route, Switch} from "react-router-dom";
import {isNil, delay} from 'lodash';
import {toast} from "react-toastify";
import LicenseAdmin from "./LicenseAdmin";
import {AuthorisationHelper, SoftwareHelper} from "./helpers";
import {Fade} from "reactstrap";
import ErrorPage from "./ErrorPage";
import {EpixSignInPage} from "./login/epixSignInPage";

Amplify.configure({
  Auth: {

    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'eu-west-1:cb5ac73b-624b-402b-9ab9-94f293ec8556',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-west-1_8FoVP7JmZ',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '337tdn344japm2rlfn0j4r0ler',

    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-1',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true
  }, Storage: {
    bucket: 'atp-tpi-tools', //REQUIRED -  Amazon S3 bucket
    region: 'eu-west-1', //OPTIONAL -  Amazon service region
  },
  API: {
    endpoints: [{
      name: "epix",
      region: "eu-west-1",
      endpoint: "https://jrsnaheli4.execute-api.eu-west-1.amazonaws.com/" + process.env.API_STAGE,
    }]
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    let cognitoGroups = props.authData.signInUserSession.accessToken.payload["cognito:groups"];
    let cognitoRoles = props.authData.signInUserSession.idToken.payload["cognito:roles"];
    let username = props.authData.username;

    if (isNil(cognitoGroups)) {
      toast.error("No group defined, please notify admin to gain access.", {
        position: toast.POSITION.TOP_CENTER
      });
      delay(this.signOut, 5000);
    }

    let softwareList = SoftwareHelper.getUserSoftwareList(cognitoGroups);
    let software = SoftwareHelper.getSoftware(username, softwareList[0]);
    let userRoles = AuthorisationHelper.getUserRoles(cognitoRoles);
    this.state = {
      username: username,
      software: software,
      userRoles: userRoles,
      softwareList: softwareList,
    };

    this.checkAdminHasSoftware();
    this.onSoftwareChange = this.onSoftwareChange.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  signOut() {
    return Auth.signOut()
      .then(() => {
        SoftwareHelper.removeSoftware(this.state.username);
        window.location.href = "/";
      })
      .catch(err => console.log(err));
  }

  onSoftwareChange(newSoftware){
    this.setState({software: newSoftware});
    SoftwareHelper.setSoftware(this.state.username, newSoftware);
  }

  render() {
    return (
      <div>
        <ToastContainer/>
        <EpixNavbar
          softwareList={this.state.softwareList}
          software={this.state.software}
          onItemSelected={this.onSoftwareChange}
          userRoles={this.state.userRoles}
          onSignOut={this.signOut}
        />
        <div className={"container"}>
          <Fade>
            <Switch>
              <Route exact path="/" render={() => <FileBrowser
                software={this.state.software}
                authorisedToUpload={AuthorisationHelper.userIsUploadAuthorised(this.state.userRoles)}/>}
              />
              <Route path="/license" render={() => <LicenseAdmin
                isAuthorised={AuthorisationHelper.userIsLicenseAuthorised(this.state.userRoles)}/>}
              />
              <Route component={ErrorPage}/>
            </Switch>
          </Fade>
        </div>
      </div>);
  }

  checkAdminHasSoftware() {
    if(this.state.software === undefined){
      toast.error("No software access defined, please notify admin to gain access.", {
        position: toast.POSITION.TOP_CENTER
      });
      delay(this.signOut, 5000);
    }
  }
}

export default withAuthenticator(App, false, [
  <EpixSignInPage/>,
  <VerifyContact/>,
  <RequireNewPassword/>,
  <ForgotPassword/>
], null, AmplifyTheme);
