import {Cache} from 'aws-amplify'
/***
 * gets the last previously selected software from cache if it exists
 * otherwise create new cached software value
 *
 * @param defaultSoftware - software added to cache if none exists
 */
const getSoftware = function (username, defaultSoftware) {
  let software = Cache.getItem(softwareKey(username));
  if(!software){
    software = defaultSoftware;
    Cache.setItem(softwareKey(username), software);
  }
  return software;
};

const setSoftware = function (username, software) {
  Cache.setItem(softwareKey(username), software);
};

const removeSoftware = function (username) {
  Cache.removeItem(softwareKey(username))
};

const softwareKey = function(username) {
  return '.' + username + '.software'
};
export const _CacheHelper = {
  getSoftware,
  setSoftware,
  removeSoftware
};