import {_CacheHelper} from "./CacheHelper";

const accentureGroupFinderExpression = /Accenture/;
const softwareNameFinderExpression = /(.*)_.*/;

const getSoftware = function (username, defaultSoftware) {
  return _CacheHelper.getSoftware(username, defaultSoftware);
};

const getUserSoftwareList = function(userGroupList){
  // return all software groups
  let softwareGroupList = userGroupList.filter(group => {
    return !group.match(accentureGroupFinderExpression);
  });

  return softwareGroupList.map(function (groupName) {
    let match = groupName.match(softwareNameFinderExpression);
    return match[1];
  });
};

const setSoftware = function (username, software) {
  _CacheHelper.setSoftware(username, software);
};

const removeSoftware = function (username) {
  _CacheHelper.removeSoftware(username);
};

export const _SoftwareHelper = {
  getSoftware,
  getUserSoftwareList,
  setSoftware,
  removeSoftware
};