import React from "react";
import {SignIn} from "aws-amplify-react";
import {
  FormSection,
  FormField,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Button,
  Input,
  InputLabel,
} from 'aws-amplify-react/dist/Amplify-UI/Amplify-UI-Components-React'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/epixSignIn.css'
import {Spinner} from "reactstrap";

export class EpixSignInPage extends SignIn {
  constructor(props) {
    super(props);
    this.handlePasswordEnterKeyPress = this.handlePasswordEnterKeyPress.bind(this)
  }

  handlePasswordEnterKeyPress(event) {
    if(event.key === 'Enter'){
      super.signIn();
    }
  }

  showComponent(theme) {
    return (<div>
      <FormSection theme={theme}>
        <div className='logo'>
          <img src={'accenture_black.png'} alt=''/>
        </div>
        <SectionHeader theme={theme}> Trading Platforms Tools Site </SectionHeader>
          <SectionBody theme={theme}>
            <FormField theme={theme}>
              <InputLabel theme={theme}>Username</InputLabel>
              <Input autoFocus
                     placeholder='Enter your username'
                     theme={theme}
                     key="username"
                     name="username"
                     onChange={this.handleInputChange}
              />
            </FormField>
            <InputLabel theme={theme}>
              Password
            </InputLabel>
            <Input placeholder='Enter your password'
                   theme={theme}
                   key="password"
                   type="password"
                   name="password"
                   onChange={this.handleInputChange}
                   onKeyPress={this.handlePasswordEnterKeyPress}
            />
            Forgot your password? Please Contact Accenture support.
            <SectionFooter theme={theme}>
              <Button theme={theme}
                      onClick={() => this.signIn()}
                      disabled={this.state.loading}
              >
                {!this.state.loading ? 'Sign in' : 'Signing in...'}
              </Button>
              {this.state.loading && <Spinner className='loadingIcon' color="primary"/>}

            </SectionFooter>
          </SectionBody>
      </FormSection>
    </div>);
  }
}