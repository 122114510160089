import React, {Component} from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  Button,
  Nav,
  Navbar,
  NavItem} from "reactstrap";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSignOutAlt, faBook} from '@fortawesome/free-solid-svg-icons'
import {NavLink} from "react-router-dom";
import {SoftwareDropdown} from "./SoftwareSelectDropdown";

import { AuthorisationHelper } from "./helpers";

library.add(faBook, faSignOutAlt);
class EpixNavbar extends Component {
  constructor (props) {
    super(props);
    this.state ={
      softwareSelected: ''
    };
  }

  render() {
    return (
      <Navbar dark={true} color="dark" expand="lg">
        <NavLink className="navbar-brand" to="/"><img src={this.props.software + '.png'} alt="" /></NavLink>
        <Nav className="ml-auto" navbar>
          <SoftwareDropdown
            onItemSelected={this.props.onItemSelected}
            softwareList={this.props.softwareList}
            software={this.props.software}
            userRoles={this.props.userRoles}
          />
          {AuthorisationHelper.userIsLicenseAuthorised(this.props.userRoles) && (
            <NavItem>
              <NavLink to="/license"><Button outline color="primary"> <FontAwesomeIcon icon="book"/> License</Button></NavLink>
            </NavItem>
          )}
          <NavItem>
            <Button onClick={() => this.props.onSignOut()} outline color="primary">  <FontAwesomeIcon icon="sign-out-alt" />  Logout</Button>
          </NavItem>
        </Nav>
      </Navbar>);
  }
}

EpixNavbar.propTypes = {
  onItemSelected: PropTypes.func.isRequired,
  softwareList: PropTypes.array.isRequired,
  software: PropTypes.string.isRequired,
  userRoles: PropTypes.array.isRequired,
  onSignOut: PropTypes.func.isRequired
};

export default EpixNavbar;
