import React from "react";
import ReactTable from 'react-table'
import PropType from 'prop-types'
import "react-table/react-table.css"

export class ModalTable extends React.Component{

  constructor (props) {
    super(props);
    this.state = {
      columns: props.columns,
      selected: null,
      selectedBackgroundColour: "#007bff",
      selectedFontColour: "white",
      mouseHoverColour: "gray"
    };
  }

  getRowOnClick(state, rowInfo) {
    let me = this;
    if(rowInfo && rowInfo.row) {
      return {
        onClick: () => {
          if(me.state.selected === rowInfo.original){ // if same row is selected then deselect
            me.setState({
              selected: null
            });
            me.props.onSelectedChanged(null);
          } else {
            me.setState({
              selected: rowInfo.original
            });
            me.props.onSelectedChanged(rowInfo.original);
          }
        }, style: {
          background: rowInfo.original === me.state.selected ? me.state.selectedBackgroundColour : null,
          color: rowInfo.original === me.state.selected ? me.state.selectedFontColour : null
        }
      }
    } else {
      return {
        onClick: () => {
          if(me.state.selected !== null){
            me.setState({
              selected: null
            });
            me.props.onSelectedChanged(null)
          }

        }, style: {
          background: null,
          color: null
        }
      }
    }
  }
  caseInsensitiveFilterMethod(filter, row, column) {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
  }
  render() {
    return (
      <ReactTable
        columns={this.state.columns}
        data={this.props.data}
        filterable={true}
        sortable={true}
        loading={this.props.isLoading}
        getTdProps={(state, rowInfo) => this.getRowOnClick(state, rowInfo)}
        defaultSorted={this.props.defaultSorted}
        defaultFilterMethod={this.caseInsensitiveFilterMethod}
      >
    </ReactTable>);
  }
}
ModalTable.propTypes = {
  columns: PropType.array.isRequired,
  data: PropType.array.isRequired,
  isLoading: PropType.bool,
  onSelectedChanged: PropType.func.isRequired,
  defaultSorted: PropType.array,
};