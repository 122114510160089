import React, {Component} from 'react';
import PropType from 'prop-types';
import "react-table/react-table.css"
import {ModalTable} from "./table/react-modal-table";
import {Button} from "reactstrap";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSignOutAlt, faBook, faSyncAlt} from '@fortawesome/free-solid-svg-icons'

import {API} from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';
import {toast} from "react-toastify";

import LicenseGeneratorWindow from "./LicenseGeneratorWindow";

const columns = [
  { accessor: "licensee", Header: "Licensee"},
  { accessor: "component", Header: "Product"},
  { accessor: "type", Header: "License Type"},
  { accessor: "issuer", Header: "Issuer"},
  { accessor: "issue_date", Header: "Issued"},
  { accessor: "expiry_date", Header: "Expires"}

];

library.add(faBook, faSignOutAlt, faSyncAlt);
class LicenseAdmin extends Component {
  constructor (props) {
    super(props);
    this.state = {
      rows: [],
      modal: false,
      backdrop: true,
      selectedRow: null,
      isTableLoading: false
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.getLicenseData()
  }

  onRowSelectChanged(rowSelected){
    this.setState({selectedRow: rowSelected});
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  onClickDownload(){
    let apiName = 'epix';
    let path = '/licenseGenerator';

    let productName = this.state.selectedRow.component;
    let expiryDate = this.state.selectedRow.expiry_date.replace(/\//g, '-'); // convert yyyy/mm/dd to yyyy-mm-dd
    let licensee = this.state.selectedRow.licensee;
    let licenseType = this.state.selectedRow.type;
    let myInit = {
      body: {
        component: productName,
        expiryDate: expiryDate,
        licensee: licensee,
        licenseType: licenseType
      }
    };

    API.post(apiName, path, myInit).then(response => {
      const data = new Blob([response], {type: 'text/xml'});
      let filename = productName + '-license.xml';

      if(window.navigator && window.navigator.msSaveOrOpenBlob) { // if IE (internet explorer)
        window.navigator.msSaveOrOpenBlob(data, filename);
      }else { // for non IE
        const xmlURL = window.URL.createObjectURL(data);
        let tempLink = document.createElement('a');
        tempLink.href = xmlURL;
        tempLink.setAttribute('download', filename);
        document.body.appendChild(tempLink); // this line required for firefox download
        tempLink.click();

        // set delay for firefox
        setTimeout(function(){
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(tempLink.href);
        }, 100);
      }
    }).catch(error => {
      toast.error("Error downloading license: " + error.response.data)
    });
  }

  getLicenseData() {
    let apiName = 'epix';
    let path = '/license';

    if(!this.state.isTableLoading) {
      this.setState({isTableLoading: true});

      API.get(apiName, path, {}).then(response => {
        // Add your code here
        let metadata = response['ResponseMetadata'];
        if(metadata['HTTPStatusCode'] === 200) { // no error so continue as normal
          this.processLicenseData(response);
          this.setState({isTableLoading: false});
        } else {
          toast.error("Error getting Data")
        }
      }).catch(error => {
        toast.error("Error getting data " + error.message)
      });
    }
  }

  /**
   * turns license data into a compatible list of dicts
   * attributes list = [licensee, issue_date, expiry_date, type, issuer, component]
   * @param data
   */
  processLicenseData(data) {
    const rowItems = data['Items'];
    let rowDataList = rowItems.map((item) => this.createLicenseRow(item['Attributes']));
    this.setState({rows: rowDataList});
  }

  createLicenseRow(rowData){
    return rowData.reduce(function (map, obj) {
      map[obj['Name']] = obj['Value'];
      return map;
    }, {});
  }

  renderAuthorisedPage(){
    return (
      <div>
        <h2>Licenses</h2>
        <div className="btn-toolbar">
          <div className="btn-group mr-2">
            <Button onClick={this.toggle} color="primary"><FontAwesomeIcon
              icon={"cloud-upload-alt"}/> Add New License</Button>
          </div>
          <div className="btn-group mr-2">
            <Button onClick={this.onClickDownload.bind(this)} color="primary" disabled={this.state.selectedRow === null}><FontAwesomeIcon
              icon={"cloud-download-alt"}/> Download License</Button>
          </div>
          <div className="btn-group mr-2">
            <Button onClick={this.getLicenseData.bind(this)} color="primary" disabled={this.state.isTableLoading}><FontAwesomeIcon
              icon={"sync-alt"}/></Button>
          </div>
          <LicenseGeneratorWindow modal={this.state.modal} toggle={this.toggle} onSubmit={this.getLicenseData.bind(this)}/>

        </div>
        <ModalTable
          columns={columns}
          data={this.state.rows}
          onSelectedChanged={this.onRowSelectChanged.bind(this)}
          isLoading={this.state.isTableLoading}
          defaultSorted={[{
            id: 'issue_date',
            desc: true
          }]}
        >
        </ModalTable>
      </div>
    );
  }

  renderUnauthorisedPage(){
    return (
      <div>
        <h2> Unauthorised Access </h2>
        <p>You are not authorised to view this page.</p>
      </div>
    )
  }
  render() {
    if (this.props.isAuthorised){
      return this.renderAuthorisedPage();
    } else {
      return this.renderUnauthorisedPage();
    }
  }
}

LicenseAdmin.propTypes = {
  isAuthorised: PropType.bool.isRequired
};

export default LicenseAdmin;
