// helper file to check if user is authorised for specific tasks
import {intersection} from "lodash";

/*
adding extra authorisation checks:

1) create list of authorised roles for a task:

  const authorisedRolesForTask = ["role1", "role2",...]*

  *NOTE: role must have same name as AWS role title

2) add function to AuthorisationHelpers:

  userIsAuthorisedToDoTask: function(userRoles) {
    return this.isAuthorised(UserRoles, authorisedRolesForTask);
  }

*/

// roles authorised to view the license page and generate/download licenses
const authorisedLicenseUserRoles = [
  "Cognito_EpixToolsAuth_Admin_Role"
];

const authorisedAdminRoles = [
  "Cognito_EpixToolsAuth_Admin_Role"
];

// roles authorised to view/use the upload button
const authorisedUploadRoles = [
  "Cognito_EpixToolsAuth_Admin_Role",
  "Cognito_EpixToolsAuth_CalMon_ReadWrite_Role",
  "Cognito_EpixToolsAuth_MxMon_ReadWrite_Role",
  "Cognito_EpixToolsAuth_Reformx_ReadWrite_Role",
  "Cognito_EpixToolsAuth_ACTC_ReadWrite_Role",
  "Cognito_EpixToolsAuth_Dashboard_ReadWrite_Role"
];

const roleFinderExpression = /:role\/(.*)/;

const getUserRoleNames = function(fullRoleNameList) {
  // list of simplified user roles
  return fullRoleNameList.map(function (fullRoleName) {
    let match = fullRoleName.match(roleFinderExpression);
    return match[1];
  });
};

/**
 * checks if user roles contains at least 1 of the authorised roles
 * @param authorisedRoles
 * @returns {boolean}
 */
const isAuthorised = function(userRoles,authorisedRoles) {
  return intersection(userRoles, authorisedRoles).length > 0;
};

export const _AuthorisationHelper = {
  getUserRoles: getUserRoleNames,
  userIsLicenseAuthorised: function(userRoles) {
    return isAuthorised(userRoles, authorisedLicenseUserRoles);
  },
  userIsAdminAuthorised: function(userRoles) {
    return isAuthorised(userRoles, authorisedAdminRoles)
  },
  userIsUploadAuthorised: function (userRoles) {
    return isAuthorised(userRoles, authorisedUploadRoles)
  }
};
