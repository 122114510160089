import React, {Component} from 'react';
import PropType from 'prop-types';
import Tree from 'rc-tree';
import 'rc-tree/dist/rc-tree.css';
import {toast} from "react-toastify";
import {Storage} from 'aws-amplify';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCloudDownloadAlt, faCloudUploadAlt, faFile, faFileAlt, faFileArchive, faFilePdf, faFolder
} from '@fortawesome/free-solid-svg-icons';
import {library} from "@fortawesome/fontawesome-svg-core";
import {endsWith, forEach, round} from 'lodash';
import {Button, Progress, Spinner} from "reactstrap";

library.add(faFilePdf, faFileAlt, faFile, faFolder, faFileArchive, faCloudDownloadAlt, faCloudUploadAlt);
class FileBrowser extends Component {
  constructor(props) {
    super(props);
    this.onClickDownload = this.onClickDownload.bind(this);
    this.onClickUpload = this.onClickUpload.bind(this);
    this.onDoubleClick = this.onDoubleClick.bind(this);
    this.onPrivateDoubleClick = this.onPrivateDoubleClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onExpand = this.onExpand.bind(this);
    this.onPrivateRepositoryExpand = this.onPrivateRepositoryExpand.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onPrivateRepositorySelect= this.onPrivateRepositorySelect.bind(this);

    this.state = {
      uploadFileName: "",
      uploadFile: null,
      treeData: [],
      expandedKeys: [],
      privateTreeData: [],
      privateExpandedKeys: [],
      progressValue: 0,
      progressColor: "success",
      progressBarClass: "d-none",
      isLoading: true,
      isPrivateLoading: true,
      software: this.props.software,
      authorisedToUpload: this.props.authorisedToUpload || false,
      selectedFile: [],
      privateSelectedFile: []
    }
  }
  componentDidMount() {
    this.listFiles(this.state.software);
    this.listPrivateFiles();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.listFiles(nextProps.software);
  }

  listFiles(software) {
    this.setState({isLoading: true});
    Storage.list(software + '/')
      .then(result => {
        let treeData = FileBrowser.createFileTree(result);
        let expandFirstItem = treeData[0].children ? [treeData[0].key] : []; // should be only single top level folder
        this.setState({
          treeData: treeData,
          expandedKeys: expandFirstItem,
          isLoading:false
        })
      })
      .catch(err => toast.error("Error accessing file list - " + err, {
        position: toast.POSITION.TOP_CENTER
      }));
  }

  listPrivateFiles() {
    this.setState({isPrivateLoading: true});
    Storage.vault.list('')
      .then(result => {
        this.setState({
          privateTreeData: FileBrowser.createFileTree(result),
          privateExpandedKeys: [],
          isPrivateLoading: false
        })
      })
      .catch(err => toast.error("Error accessing private file list - " + err, {
        position: toast.POSITION.TOP_CENTER
      }));
  }

  static createFileTree(result) {
    let treeList = [];
    forEach(result, (value) => {
      FileBrowser.addItemToTreeList(treeList, value.key);
    });
    return treeList;
  };

  /**
   * specify a full path to be added to tree list
   * @param treeList
   * @param path
   */
  static addItemToTreeList(treeList, path) {
    let separatedPath = path.split('/');
    let itemName = separatedPath.pop();

    let childList = treeList;
    forEach(separatedPath, (value, index) => {
      let foundChild;
      forEach(childList, (child => {
        if(child.title === value){
          foundChild = child;
        }
      }));
      if(!foundChild) {
        // create folder if invisible
        let folder = FileBrowser.createFolder(separatedPath.slice(0, index + 1).join('/') + '/', value);
        childList.push(folder);
        childList = folder.children;
      }else {
        childList = foundChild.children;
      }
    });
    if(itemName !== ''){ // '' means path given is a folder
      childList.push(FileBrowser.createFile(path, itemName))
    }
  }

  static createFolder(path, title) {
    return {
      icon: <FontAwesomeIcon icon={"folder"} color={'#f4b942'}/>,
      key: path,
      children: [],
      selectable: false,
      title: title
    };
  }

  static createFile(path, title){
      return {icon: this.getFileIcon(title), key: path, title: title};
  }

  static getFileIcon(filename) {
    if(endsWith(filename, 'pdf')) {
      return <FontAwesomeIcon icon={'file-pdf'} color={'red'}/>;
    } else if(endsWith(filename, 'tar.gz') || endsWith(filename, 'war')) {
      return <FontAwesomeIcon icon={'file-archive'}/>;
    } else if (endsWith(filename, '.txt') || endsWith(filename, '.config') || endsWith(filename, '.conf')){
      return <FontAwesomeIcon icon={'file-alt'}/>;
    } else {
        return <FontAwesomeIcon icon={'file'}/>;
    }
  }

  static isFolder(nodeValue) {
    return endsWith(nodeValue, '/');
  }

  onDoubleClick(event, node) {
    let selectedFile = node.props.eventKey;
    if (FileBrowser.isFolder(selectedFile)) {
      return;
    }
    this.downloadFile(selectedFile);
  }

  onPrivateDoubleClick(event, node) {
    let selectedFile = node.props.eventKey;
    if (FileBrowser.isFolder(selectedFile)) {
      return;
    }
    this.downloadFile(selectedFile, {level: 'private'});
  }

  downloadFile(selectedFile, config) {
    Storage.get(selectedFile, {expires: 60, ...config || {}})
      .then(result => {
        window.open(result, "_blank");
      })
      .catch(err => toast.error("Error Downloading File - " + err, {
        position: toast.POSITION.TOP_CENTER
      }))
  }

  onClickDownload() {
    if(this.state.selectedFile.length) {
      this.downloadFile(this.state.selectedFile);
    } else if(this.state.privateSelectedFile.length) {
      this.downloadFile(this.state.privateSelectedFile, {level: 'private'});
    }
  };

  onClickUpload() {
    let uploadFile = this.state.uploadFile;
    if(!uploadFile) {
      toast.error("Please select a file");
      return;
    }
    this.setState({
      progressBarClass: "d-block"
    });
    let state = this;
    Storage.put(uploadFile.name, uploadFile, {
      progressCallback(progress) {
        state.setState({
          progressValue: round(progress.loaded / progress.total * 100)
        });
      }, level: 'private'
    }).then(result => {
      this.listPrivateFiles(); // reload private files to show newly added file
      toast.success("Success Uploading File - " + result.key, {
        position: toast.POSITION.TOP_CENTER
      })
    }).catch(err => toast.error("Error Uploading File - " + err, {
      position: toast.POSITION.TOP_CENTER
    })).finally(() => {
      // reset file on success or failure
      this.setState({
        uploadFileName: "", uploadFile: null, progressValue:0, progressBarClass: "d-none"
      });
    });
  };

  onChange(selectedKeys) {
    let file = selectedKeys.target.files[0];
    if (file) {
      this.setState({
        uploadFile: file, uploadFileName: file.name
      });
    }
  };

  onExpand(selectedKeys) {
    this.setState({
      expandedKeys: selectedKeys
    });
  };

  onPrivateRepositoryExpand(selectedKeys) {
    this.setState({
      privateExpandedKeys: selectedKeys
    })
  };

  onSelect(selectedKeys) {
    this.setState({
      selectedFile: selectedKeys,
      privateSelectedFile: []
    });
  };

  onPrivateRepositorySelect(selectedKeys) {
    this.setState({
      selectedFile: [],
      privateSelectedFile: selectedKeys
    })
  };

  renderUploadFunctionality(){
    return (<div style={{width: '500px'}} className="input-group">
        <div className="custom-file">
          <input type="file" className="custom-file-input" onChange={(e) => this.onChange(e)}/>
          <label className="custom-file-label">{this.state.uploadFileName}</label>
        </div>
        <div className="input-group-append">
          <Button onClick={this.onClickUpload} color="primary"
                  disabled={this.state.uploadFile === null}><FontAwesomeIcon
            icon={"cloud-upload-alt"}/> Upload</Button>
        </div>
      </div>);
  }

  renderLibrary(){
    return (<div>
        <div className="btn-toolbar">
          <div className="btn-group mr-2">
            <Button onClick={this.onClickDownload} disabled={!this.state.selectedFile.length && !this.state.privateSelectedFile.length} color="primary"><FontAwesomeIcon
              icon={"cloud-download-alt"}/> Download</Button>
          </div>
        </div>
      <Progress className = {this.state.progressBarClass} animated color = {this.state.progressColor} value = {this.state.progressValue} >
      {this.state.progressValue}%</Progress>
      <Tree
        expandedKeys = {this.state.expandedKeys}
        className = "list-group"
        showLine
        onDoubleClick={this.onDoubleClick}
        onExpand = {this.onExpand}
        onSelect = {this.onSelect}
        treeData = {this.state.treeData}
        selectedKeys={this.state.selectedFile}
        />
      </div>
    );
  }

  renderPrivateLibrary() {
    return (<div>
        {(this.state.privateTreeData.length !==0 || this.state.authorisedToUpload) && <h3 style={{paddingTop: '20px'}}> Private Files </h3>}
        {this.state.authorisedToUpload && this.renderUploadFunctionality()}
        {this.state.isPrivateLoading ? FileBrowser.renderLoading(): this.renderPrivateTree()}
      </div>
      );
  }

  renderPrivateTree() {
    if(this.state.privateTreeData.length !== 0) {
      return <Tree
        expandedKeys={this.state.privateExpandedKeys}
        className="list-group"
        showLine
        onDoubleClick={this.onPrivateDoubleClick}
        onExpand={this.onPrivateRepositoryExpand}
        onSelect={this.onPrivateRepositorySelect}
        treeData={this.state.privateTreeData}
        selectedKeys={this.state.privateSelectedFile}
      />
    } else {
      if(this.state.authorisedToUpload) {
        return "No private files."
      }
    }
  }


  static renderLoading(){
    return(<div>
      <Spinner color="primary" style={{ width: '5rem', height: '5rem' }}/>
    </div>);
  }

  render() {
      return(
    <div>
      <h2>Library</h2>
      {this.state.isLoading? FileBrowser.renderLoading() : this.renderLibrary()}
      {this.state.isPrivateLoading ? FileBrowser.renderLoading() : this.renderPrivateLibrary()}
    </div>);
  }

}

FileBrowser.propTypes = {
  software: PropType.string.isRequired,
  authorisedToUpload: PropType.bool,
};
export default FileBrowser;
