import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from "reactstrap";
import React, {Component} from 'react';
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import {FormErrors} from "./FormErrors";

class LicenseGeneratorWindow extends Component {

  constructor(props) {
    super(props);
    this.onLicenseFormSubmit = this.onLicenseFormSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      date: '',
      product: '',
      licensee: '',
      licenseType: '',
      formErrors: {date: '', product: '', licensee: '', licenseType: ''},
      dateValid: false,
      productValid: false,
      licenseeValid: false,
      licenseTypeValid: false,
      formValid: false,
      components: [],
      licenseTypes: []
    };

    this.getLicenseDetails();
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value
    }, () => this.validateField(name, value));
  }

  validateField(fieldName, value){
    let fieldValidationErrors = this.state.formErrors;
    let licenseeValid = this.state.licenseeValid;
    let productValid = this.state.productValid;
    let licenseTypeValid = this.state.licenseTypeValid;
    let dateValid = this.state.dateValid;

    switch(fieldName) {
      case 'licensee':
        licenseeValid = value !== '';
        fieldValidationErrors.licensee = licenseeValid ? '' : 'is too short';
        if(licenseeValid){
          // xml invalid char check
          let invalidXMLExpression = /.*([&<>'"])/;
          licenseeValid = !value.match(invalidXMLExpression);
          fieldValidationErrors.licensee = licenseeValid ? '' : 'cannot include any of the following: &<>\'"';
        }
        break;
      case 'product':
        productValid = value !== '';
        fieldValidationErrors.product = productValid ? '' : 'is invalid';
        break;
      case 'licenseType':
        licenseTypeValid = value !== '';
        fieldValidationErrors.licenseType = licenseTypeValid ? '' : 'is invalid';
        break;
      case 'date':
        let myDate = new Date();

        let year = myDate.getFullYear();

        let month = myDate.getMonth()+1;
        if(month <= 9)
          month = '0'+month; // 0 padding for string date comparison

        let day= myDate.getDate();
        if(day <= 9)
          day = '0'+day;

        let currentDate = year +'-'+ month +'-'+ day;
        dateValid = value !== '' && value > currentDate;
        fieldValidationErrors.date = dateValid ? '' : 'must not be empty and be in the future';
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      licenseeValid: licenseeValid,
      productValid: productValid,
      licenseTypeValid: licenseTypeValid,
      dateValid: dateValid
    }, this.validateForm)
  }
  validateForm() {
    this.setState({formValid: this.state.licenseeValid &&
                                    this.state.productValid &&
                                    this.state.licenseTypeValid &&
                                    this.state.dateValid
    });
  }
  getLicenseDetails() {
    let apiName = 'epix';
    let path = '/license/details';
    API.get(apiName, path, {}).then(response => {
      let componentList = response.body.components;
      let typesList = response.body.types;
      this.setState({
        components: componentList,
        licenseTypes: typesList
      });
      this.setState({
        product: componentList[0].id,
        licenseType: typesList[0].id
      });
      this.validateField('product', this.state.product);
      this.validateField('licenseType', this.state.licenseType);
    }).catch(error => {
      toast.error("Error getting license details: " + error.message)
    });
  }

  onLicenseFormSubmit() {
    let apiName = 'epix';
    let path = '/license';
    let productName = this.state.product;
    let expiryDate = this.state.date.replace(/-/g, '/'); // convert yyyy-mm-dd to yyyy/mm/dd (required for license generation)
    let licensee = this.state.licensee;
    let licenseType = this.state.licenseType;
    let myInit = {
      body: {
        component: productName,
        expiryDate: expiryDate,
        licensee: licensee,
        licenseType: licenseType
      }
    };

    API.post(apiName, path, myInit).then(response => {
      if("errorMessage" in response){
        toast.error(response.errorMessage)
      }else {
        //this.resetForm();
        this.props.onSubmit();
        this.props.toggle();
      }
    }).catch(error => {
      toast.error("Error creating licensee " + error.message)
    });
  }

  static createOption(item){
    return <option key={item.id} value={item.id}>{item.value}</option>
  }

  render() {
    return (
      <Modal isOpen={this.props.modal} toggle={this.props.toggle}>
        <ModalHeader toggle={this.toggle}>License Generator</ModalHeader>
        <FormErrors formErrors={this.state.formErrors} />
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleLicensee">Licensee</Label>
              <Input value={this.state.licensee} type="text" name="licensee" placeholder={"Enter Licensee Name here"} onChange={this.handleInputChange}/>
            </FormGroup>
            <FormGroup>
              <Label for="exampleSelect">Product</Label>
              <Input value={this.state.product} type="select" name="product" id="exampleSelect" onChange={this.handleInputChange}>
                {this.state.components.map(LicenseGeneratorWindow.createOption)}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="exampleSelectMulti">License Type</Label>
              <Input value={this.state.licenseType} type="select" name="licenseType" id="exampleSelect" onChange={this.handleInputChange}>
                {this.state.licenseTypes.map(LicenseGeneratorWindow.createOption)}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="exampleText">Expiry Date</Label>
              <Input
                value={this.state.date}
                type="date"
                name="date"
                placeholder="YYYY/MM/DD"
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <Button onClick={this.onLicenseFormSubmit} color={"primary"} disabled={!this.state.formValid}>Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

export default LicenseGeneratorWindow;
